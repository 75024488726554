import gql from 'graphql-tag';

export const seo = gql`
    fragment seo on Ether_SeoData {
        description
        title
        social {
            facebook {
                description
                title
                image {
                    url(width: 1200, height: 628, immediately: false)
                }
            }
            twitter {
                description
                title
                image {
                    url(width: 1200, height: 628, immediately: false)
                }
            }
        }
    }
`;

export const seoExhibition = gql`
    fragment seoExhibition on exhibition_Submission {
        __typename
        title
        subheadline
        seoImage: keyImage @transform(width: 1200, height: 628, format: "jpeg") {
            url
            width
            height
        }
    }
`;
