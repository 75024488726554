import type { KubaparisOverlayVideosFragment, VideoAssetFragment } from '$gen/graphql';
import gql from 'graphql-tag';

export const kubaparisOverlayVideos = gql`
    fragment kubaparisOverlayVideos on kubaparisVideos_BlockType {
        ... on kubaparisVideos_BlockType {
            __typename
            desktop {
                ...videoAsset
            }
            mobile {
                ...videoAsset
            }
            backgroundColor
            desktopDarkMode {
                ...videoAsset
            }
            mobileDarkMode {
                ...videoAsset
            }
            backgroundColorDarkMode
        }
    }
    fragment videoAsset on assets_Asset {
        __typename
        url
    }
`;
export const isKubaparisOverlayVideos = (item): item is KubaparisOverlayVideosFragment => {
    return (item as KubaparisOverlayVideosFragment).__typename === 'kubaparisVideos_BlockType';
};

export const isVideoAsset = (item): item is VideoAssetFragment => {
    return (item as VideoAssetFragment).__typename === 'assets_Asset';
};
