import gql from 'graphql-tag';

export const footer = gql`
    fragment footer on footer_GlobalSet {
        __typename
        id
        name
        newsletterLabel
        newsletterLink
        about {
            __typename
            ... on about_footerAbout_BlockType {
                __typename
                id
                aboutText
            }
        }
        followUs {
            __typename
            ... on followUs_socialMediaEntry_BlockType {
                __typename
                id
                externalLink
                label
            }
        }
        blogContactEmail
        blogContactLabel
        studioContactEmail
        studioContactLabel
        linkToSingles {
            id
            uri
            title
        }
    }
`;
